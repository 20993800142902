<template>
  <section class="screening">
    <div class="header m300 frsbc">
      <div class="current_show frfsfs">
        <div class="text">当前显示：</div>
        <div class="text" v-if="!isAll">全部</div>
        <div class="label_list f" v-else>
          <div class="label_item mr5 frsbc pl5" v-for="(item, index) in currentShow" :key="index">
            <div class="show frfsc">
              {{item.label1}}：<span>{{item.label2.join(',')}}</span>
            </div>
            <div class="close ml5" @click="deleteLabel(item.label1)"></div>
          </div>
        </div>
      </div>
      <div class="downloadd" @click="downloaddBook" v-if="token && regularFlag == 2">下载完整书单</div>
    </div>
    <div class="body">
      <div class="level1_item frsbc" :class="{'multi_selects': isMultiSelect && currentIndex === index1}" :style="{'height': isMore && currentIndex === index1 ? '180px' : '60px'}" v-for="(item1, index1) in screeningOptions" :key="index1">
        <div class="level1" :style="{'line-height': isMore && currentIndex === index1 ? '179px' : '59px'}">
          {{item1.label}}
        </div>
        <div :ref="'level2' + index1" class="level2 frfsc" :style="{'overflow': isMore && currentIndex === index1 ? 'scroll' : 'hidden'}">
          <div class="m015" v-for="(item2, index2) in item1.child" :key="index2" @click="labelClick(index1, item1.label, item2.label, item2.value)">
            <el-checkbox v-model="item2.isSelected" v-if="isMultiSelect && !isMore && currentIndex === index1"></el-checkbox>
            {{item2.label}}
          </div>
          <div class="choose_btns frcc" v-if="isMultiSelect && currentIndex === index1">
            <div class="mr15" :class="{'choose_btn': confirm > 0}" @click="determineClick(index1, item1.label)">确定</div>
            <div @click="cancelClick">取消</div>
          </div>
        </div>
        <div class="operation frfsc ml30" v-if="!item1.isMultiSelect">
          <div class="box more mr15 frcc" @click="moreClick(index1)" v-if="item1.child.length > 10">
            <div class="text">{{isMore && currentIndex === index1 ? '收起' : '更多'}}</div>
            <div class="down ml5" :style="{'transform': isMore && currentIndex === index1 ? 'rotate(135deg)' : 'rotate(-45deg)'}"></div>
          </div>
          <div class="box multi_select mr15" @click="multiSelect(index1)">+ 多选</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import {
    getRegularFlag
  } from 'utils/user.js';

  export default {
    props: {
      screeningOptions: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        regularFlag: getRegularFlag(), // 1 银卡 2 金卡
        labelList: [{}, {}, {}, {}, {}],
        valueList: [[], [], [], [], []],
        isMore: false,
        isMultiSelect: false,
        currentIndex: 0
      }
    },

    computed: {
      token() {
        return this.$store.state.user.token;
      },
      isAll() {
        return this.labelList.some(item => item.label1);
      },
      currentShow() {
        return this.labelList.filter(item => item.label1);
      },
      confirm() {
        return this.screeningOptions[this.currentIndex].child.filter(item => item.isSelected).length;
      }
    },

    methods: {
      labelClick(index1, label1, label2, value) {
        if(this.isMultiSelect && this.currentIndex === index1) {
          return;
        }
        this.$set(this.labelList[index1], 'label1', label1);
        this.$set(this.labelList[index1], 'label2', [label2]);
        this.valueList[index1].length = 0;
        this.valueList[index1].push(value);
        this.$emit('labelClick', {
          type: index1 <= 1 ? 'ordinary' : index1 == 2 ? 'brand' : index1 == 3 ? 'award' : 'press',
          valueList: index1 <= 1 ? this.valueList.slice(0, 3) : this.valueList[index1]
        });
      },
      moreClick(index) {
        this.screeningOptions[this.currentIndex].isMultiSelect = false;
        this.$refs['level2' + index][0].scrollTop = 0;
        this.isMore = !this.isMore;
        this.currentIndex = index;
      },
      multiSelect(index) {
        this.isMore = false;
        this.isMultiSelect = false;
        this.screeningOptions[this.currentIndex].isMultiSelect = false;
        this.isMultiSelect = !this.isMultiSelect;
        this.currentIndex = index;
        this.screeningOptions[this.currentIndex].isMultiSelect = true;
      },
      determineClick(index, label1) {
        if(this.confirm == 0) {
          return;
        }
        this.$set(this.labelList[index], 'label1', label1);
        let arr = [];
        this.screeningOptions[index].child.forEach(item => {
          if(item.isSelected) {
            arr.push(item.label);
            this.valueList[index].push(item.value);
          }
        });
        this.$set(this.labelList[index], 'label2', arr);
        this.$emit('labelClick', {
          type: index <= 1 ? 'ordinary' : index == 2 ? 'brand' : index == 3 ? 'award' : 'press',
          valueList: index <= 1 ? this.valueList.slice(0, 2) : this.valueList[index]
        });
        this.cancelClick();
      },
      cancelClick() {
        this.$refs['level2' + this.currentIndex][0].scrollTop = 0;
        this.isMore = false;
        this.isMultiSelect = false;
        this.screeningOptions[this.currentIndex].isMultiSelect = false;
      },
      deleteLabel(label1) {
        this.labelList.map(item => {
          if(item.label1 == label1) {
            item.label1 = '';
            item.label2.length = 0;
          }
        });
        this.screeningOptions.forEach((item, index) => {
          if(item.label === label1) {
            this.valueList[index].length = 0;
            item.child.map(i => {
              i.isSelected = false;
            });
          }
        });
        this.$emit('labelClick', {
          type: 'delete',
          str: label1
        });
      },
      downloaddBook() {
        this.$emit('downloaddBook');
      }
    }
  }
</script>

<style lang="less" scoped>
  .screening {
    .header {
      .current_show {
        .text {
          font-size: var(--fontSize16);
          color: var(--color000);
          font-weight: 400;
          line-height: 30px;
        }
        .label_list {
          .label_item {
            min-width: 180px;
            height: 30px;
            background: var(--colorf7f7f7);
            border: 1px solid var(--colore6e6e6);
            cursor: pointer;
            &:hover {
              width: 100%;
              background: var(--colorfff);
              border: 1px solid var(--colorfe1818);
              .show {
                span {
                  width: 100%;
                  // transition: width 0.5s;
                }
              }
              .close {
                background: url('~assets/images/close-hover.png') no-repeat;
                background-size: 100% 100%;
              }
            }
            .show {
              flex: 1;
              font-size: var(--fontSize12);
              color: var(--color000);
              font-weight: 400;
              line-height: 28px;
              white-space: nowrap;
              span {
                display: block;
                width: 80px;
                color: var(--colorfe1818);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
            .close {
              width: 28px;
              height: 28px;
              background: url('~assets/images/close.png') no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
      .downloadd {
        font-size: var(--fontSize16);
        color: var(--color1890ff);
        font-weight: bold;
        cursor: pointer;
      }
    }
    .body {
      background: var(--colorfff);
      border: 1px solid var(--colore8e8e8);
      .level1_item {
        position: relative;
        height: 60px;
        border-bottom: 1px solid var(--colore8e8e8);
        &:last-child {
          border-bottom: none;
        }
        .level1 {
          width: 120px;
          height: 100%;
          background: var(--colorf7f7f7);
          font-size: var(--fontSize16);
          color: var(--color000);
          font-weight: bold;
          text-align: center;
          line-height: 59px;
        }
        .level2 {
          flex: 1;
          height: 100%;
          flex-wrap: wrap;
          overflow: hidden;
          div {
            font-size: var(--fontSize14);
            color: var(--color666);
            font-weight: 400;
            line-height: 59px;
            white-space: nowrap;
            cursor: pointer;
            &:hover {
              color: var(--colored6d00);
            }
          }
          .choose_btns {
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 666;
            div {
              width: 50px;
              height: 20px;
              border-radius: 4px;
              font-size: var(--fontSize12);
              text-align: center;
              line-height: 18px !important;
            }
            :nth-child(1) {
              border: 1px solid var(--colorededed);
              background: var(--colorf7f7f7);
              color: var(--colorccc);
              font-weight: 500;
            }
            :nth-child(2) {
              border: 1px solid var(--colord9d9d9);
              background: var(--colorf7f7f7);
              color: var(--color000);
              font-weight: 500;
            }
          }
          .choose_btn {
            background: var(--colored6d00) !important;
            color: var(--colorfff) !important;
          }
        }
        .operation {
          .box {
            width: 60px;
            height: 30px;
            background: var(--colorfff);
            border: 1px solid var(--colord9d9d9);
            border-radius: 4px;
            cursor: pointer;
          }
          .more {
            .text {
              font-size: var(--fontSize12);
              color: var(--color333);
              font-weight: 500;
            }
            .down {
              width: 5px;
              height: 5px;
              border-left: 1px solid var(--color333);
              border-bottom: 1px solid var(--color333);
              border-right: none;
              border-top: none;
              transform:rotate(-45deg);
            }
            &:hover {
              .text {
                color: var(--colored6d00);
              }
              .down {
                border-left: 1px solid var(--colored6d00);
                border-bottom: 1px solid var(--colored6d00);
              }
            }
          }
          .multi_select {
            text-align: center;
            line-height: 28px;
            &:hover {
              color: var(--colored6d00);
            }
          }
        }
      }
      .multi_selects {
        height: 180px !important;
        border: 4px solid var(--colorf94);
        background: var(--colorfff);
        .level1 {
          background: var(--colorfff3e8);
          line-height: 52px !important;
        }
        .level2 {
          align-items: flex-start !important;
          overflow: scroll !important;
          padding-bottom: 50px;
          div {
            line-height: 52px !important;
          }
        }
      }
    }
  }
</style>
