<template>
  <section class="goods_list">
    <div class="header mt15">
      <div class="top frfsc">
        <div class="left">{{unionFlag == 1 ? '联盟价' : '批发价'}}</div>
        <div class="right frsbc">
          <div class="frfsc">
            <div class="price mr30 frfsc" v-if="unionFlag == 1">
              <div class="minimum frcc">
                ￥<input class="ml5" type="text" v-model="startUnionPrice">
              </div>
              <div class="to m05"></div>
              <div class="biggest frcc">
                ￥<input class="ml5" type="text" v-model="endUnionPrice">
              </div>
              <div class="search_btn ml5" @click="straightPiJiaSearch" v-if="startUnionPrice && endUnionPrice">查询</div>
              <div class="search_btn ml5" @click="reset" v-if="startUnionPrice || endUnionPrice">重置</div>
            </div>
            <div class="price mr30 frfsc" v-else>
              <div class="minimum frcc">
                ￥<input class="ml5" type="text" v-model="startTradePrice">
              </div>
              <div class="to m05"></div>
              <div class="biggest frcc">
                ￥<input class="ml5" type="text" v-model="endTradePrice">
              </div>
              <div class="search_btn ml5" @click="straightPiJiaSearch" v-if="startTradePrice && endTradePrice">查询</div>
              <div class="search_btn ml5" @click="reset" v-if="startTradePrice || endTradePrice">重置</div>
            </div>
            <div class="stock">
              <el-checkbox v-model="hasValidNum" @change="chooseStock">只看有货</el-checkbox>
            </div>
          </div>
          <div class="frfsc">
            <div class="total mr15">
              共{{total}}件商品
            </div>
            <div class="page_size mr15">
              每页显示
              <el-select class="m05" size="mini" v-model="newPageSize" @change="pageSizeChange">
                <el-option v-for="(item, index) in pageSizeOptions" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
              件
            </div>
            <div class="page_index frfsc">
              <div class="before m015" @click="previousPage">
                <img :src="newPageIndex === 1 ? 'https://hullobuket.oss-cn-shenzhen.aliyuncs.com/dev/user/1654758935475.png' : 'https://hullobuket.oss-cn-shenzhen.aliyuncs.com/dev/user/1654758953277.png'">
              </div>
              <div class="number">
                <span>{{newPageIndex}}</span>/{{pages}}
              </div>
              <div class="after m015" @click="nextPage">
                <img :src="newPageIndex !== pages ? 'https://hullobuket.oss-cn-shenzhen.aliyuncs.com/dev/user/1654758995344.png' : 'https://hullobuket.oss-cn-shenzhen.aliyuncs.com/dev/user/1654759021584.png'">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom frsbc p15">
        <div class="left">
          <el-checkbox v-model="isAllSelect" @change="chooseAllSelect">本页全选</el-checkbox>
        </div>
        <div class="right" @click="batchAddPurchaseOrder">批量加入采购单</div>
      </div>
    </div>
    <div class="goods_item frsbc" v-for="(item, index) in goodsList" :key="item.goodsCode">
      <div class="left">
        <el-checkbox v-model="item.isSelected" @change="selectedChange($event, index)"></el-checkbox>
      </div>
      <div class="middle m015 frfsc" @click="goodsDetail(item.goodsCode)">
        <div class="goods_picture mr15">
          <img :src="item.validNum > 0 ? item.picture : 'https://hullobuket.oss-cn-shenzhen.aliyuncs.com/user/1655703087639.png'">
        </div>
        <div class="goods_detail">
          <div class="goods_name">
            {{item.goodsName}}
          </div>
          <div class="straight_pi_jia mt20 frfsc">
            <div class="price">
              {{unionFlag == 1 ? '联盟价' : regularFlag == 2 ? '批发价' : '折扣价'}}￥{{regularFlag == 2 ? item.tradePrice : item.extendPrice}}
            </div>
            <div class="discount ml5">
              {{regularFlag == 2 ? item.discountTrade : item.discountRate}}折
            </div>
            <div class="pricing ml5" v-if="regularFlag != 2">
              <span>￥{{item.basePrice}}</span>
            </div>
          </div>
          <div class="straight_pi mt10 frfsc" v-if="regularFlag != 2">
            <div class="price">
              ￥{{item.tradePrice}}
            </div>
            <div class="picture ml5">
              <img src="~assets/images/straight-pi-jia.png">
            </div>
            <div class="instructions ml5">
              订单满{{fullAmount}}元或金卡会员可享
            </div>
          </div>
          <div class="pricing mt10" v-else>
            定价<span>￥{{item.basePrice}}</span>
          </div>
        </div>
      </div>
      <div class="right" :class="{'no_inventory': item.validNum <= 0}" @click.stop="addPurchaseOrder(item.validNum, item.goodsCode)">加入采购单</div>
    </div>
    <div class="no_goods" @click="clearSearchConditions" v-if="$parent.isNoData">
      <img src="~assets/images/no-goods.png">
    </div>
  </section>
</template>

<script>
  import {
    getRegularFlag,
    getUnionFlag
  } from 'utils/user.js';
  import {
    getTopUp,
    addPurchaseOrder,
    batchAddPurchaseOrder
  } from 'api/purchaseOrder.js';

  export default {
    props: {
      goodsList: {
        type: Array,
        default: () => []
      },
      total: {
        type: Number,
        default: 0
      },
      pages: {
        type: Number,
        default: 0
      },
      pageIndex: {
        type: Number,
        default: 1
      },
      pageSize: {
        type: Number,
        default: 10
      }
    },

    created() {
      this.getTopUp();
    },

    data() {
      return {
        regularFlag: getRegularFlag(), // 1 银卡 2 金卡
        startTradePrice: '',
        endTradePrice: '',
        startUnionPrice: '',
        endUnionPrice: '',
        hasValidNum: false,
        newPageIndex: 1,
        pageSizeOptions: [
          {label: 10, value: 10},
          {label: 20, value: 20},
          {label: 30, value: 30},
          {label: 40, value: 40},
          {label: 50, value: 50}
        ],
        newPageSize: 10,
        isAllSelect: false,
        fullAmount: 0,
        unionFlag: getUnionFlag()
      }
    },

    computed: {
      token() {
        return this.$store.state.user.token;
      }
    },

    methods: {
      getTopUp() {
        getTopUp().then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.fullAmount = res.data;
        });
      },
      straightPiJiaSearch() {
        if(this.unionFlag == 1) {
          this.$emit('search', {
            startUnionPrice: this.startUnionPrice,
            endUnionPrice: this.endUnionPrice
          });
        }else {
          this.$emit('search', {
            startTradePrice: this.startTradePrice,
            endTradePrice: this.endTradePrice
          });
        }
      },
      reset() {
        if(this.unionFlag == 1) {
          this.startUnionPrice = '';
          this.endUnionPrice = '';
          this.$emit('search', {
            startUnionPrice: '',
            endUnionPrice: ''
          });
        }else {
          this.startTradePrice = '';
          this.endTradePrice = '';
          this.$emit('search', {
            startTradePrice: '',
            endTradePrice: ''
          });
        }
      },
      chooseStock(hasValidNum) {
        this.$emit('search', {
          hasValidNum
        });
      },
      previousPage() {
        if(this.newPageIndex <= 1) {
          return;
        }
        this.isAllSelect = false;
        this.newPageIndex --;
        this.$emit('update:pageIndex', this.newPageIndex);
      },
      nextPage() {
        if(this.newPageIndex >= this.pages) {
          return;
        }
        this.isAllSelect = false;
        this.newPageIndex ++;
        this.$emit('update:pageIndex', this.newPageIndex);
      },
      pageSizeChange(pageSize) {
        this.$emit('update:pageSize', pageSize);
      },
      chooseAllSelect(isAllSelect) {
        this.goodsList.forEach((item, index) => {
          if(!isAllSelect) {
            this.goodsList[index].isSelected = false;
            return;
          }
          this.goodsList[index].isSelected = true;
        });
      },
      batchAddPurchaseOrder() {
        if(!this.token) {
          this.$router.push({
            name: 'login'
          });
          return;
        }
        let goodsCode = [];
        this.goodsList.forEach(item => {
          if(item.isSelected) {
            goodsCode.push(item.goodsCode);
          }
        });
        batchAddPurchaseOrder({
          goodsCode
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$store.dispatch('getPurchaseOrderData');
        });
      },
      goodsDetail(goodsCode) {
        this.$router.push({
          name: 'goodsDetail',
          params: {
            goodsCode
          }
        });
      },
      selectedChange(value, index) {
        this.goodsList[index].isSelected = value;
      },
      addPurchaseOrder(validNum, goodsCode) {
        if(!this.token) {
          this.$router.push({
            name: 'login'
          });
          return;
        }
        if(validNum <= 0) {
          this.$message.warning('该商品暂无库存！');
          return;
        }
        addPurchaseOrder({
          goodsCode,
          number: 1
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success('加入采购单成功！');
          this.$store.dispatch('getPurchaseOrderData');
        });
      },
      clearSearchConditions() {
        this.$emit('clearSearchConditions');
      }
    },

    watch: {
      pageIndex(newValue) {
        this.newPageIndex = newValue;
      },
      pageSize(newValue) {
        this.newPageSize = newValue;
      }
    }
  }
</script>

<style lang="less" scoped>
  .goods_list {
    .header {
      background: var(--colorfff);
      border-bottom: 8px solid var(--colorf2f2f2);
      .top {
        height: 50px;
        background: var(--colorf7f7f7);
        border: 1px solid var(--colore8e8e8);
        .left {
          width: 120px;
          height: 100%;
          font-size: var(--fontSize16);
          color: var(--color000);
          font-weight: bold;
          text-align: center;
          line-height: 48px;
        }
        .right {
          flex: 1;
          .price {
            div {
              width: 60px;
              height: 30px;
              background: var(--colorfff);
              border: 1px solid var(--colord9d9d9);
              border-radius: 4px;
              font-size: var(--fontSize12);
              color: var(--color999);
              font-weight: 500;
              input {
                width: 30px;
                font-size: var(--fontSize12);
                color: var(--color999);
                font-weight: 500;
              }
            }
            .to {
              width: 10px;
              height: 1px;
              background: var(--colord9d9d9);
            }
            .search_btn {
              width: 50px;
              height: 25px;
              background: var(--colorf7f7f7);
              border: 1px solid var(--colord9d9d9);
              border-radius: 4px;
              font-size: var(--fontSize12);
              color: var(--color000);
              font-weight: 500;
              text-align: center;
              line-height: 23px;
              cursor: pointer;
            }
          }
          .stock {
            .el-checkbox {
              color: var(--color000);
            }
          }
          .total {
            font-size: var(--fontSize12);
            color: var(--color000);
            font-weight: 500;
          }
          .page_size {
            font-size: var(--fontSize12);
            color: var(--color000);
            font-weight: 500;
            .el-select {
              width: 75px !important;
            }
          }
          .page_index {
            .before, .after {
              width: 24px;
              height: 24px;
              cursor: pointer;
            }
            .number {
              font-size: var(--fontSize12);
              color: var(--color000);
              span {
                color: var(--colorf00);
              }
            }
          }
        }
      }
      .bottom {
        .left {
          .el-checkbox {
            color: var(--color1890ff);
          }
        }
        .right {
          width: 120px;
          height: 30px;
          background: var(--colorfff);
          border: 1px solid var(--color1890ff);
          font-size: var(--fontSize14);
          color: var(--color1890ff);
          font-weight: bold;
          text-align: center;
          line-height: 28px;
          cursor: pointer;
        }
      }
    }
    .goods_item {
      border-bottom: 1px solid var(--colorf2f2f2);
      padding: 50px 15px;
      cursor: pointer;
      &:hover {
        border: 1px solid var(--colorf0f0f0);
        box-shadow: 0px 1px 32px 0px rgba(238, 238, 238, 0.78);
      }
      .left {

      }
      .middle {
        flex: 1;
        .goods_picture {
          width: 100px;
          height: 100px;
        }
        .goods_detail {
          .goods_name {
            font-size: var(--fontSize16);
            color: var(--color000);
            font-weight: bold;
          }
          .straight_pi_jia {
            .price {
              font-size: var(--fontSize16);
              color: var(--colorfe1818);
              font-weight: bold;
            }
            .discount {
              width: 40px;
              height: 20px;
              background: var(--colorfff);
              border: 1px solid var(--colorfe1818);
              border-radius: 4px;
              font-size: var(--fontSize12);
              font-weight: bold;
              color: var(--colorfe1818);
              text-align: center;
              line-height: 18px;
            }
          }
          .straight_pi {
            .price {
              font-size: var(--fontSize16);
              color: var(--color000);
              font-weight: bold;
            }
            .picture {
              width: 61px;
              height: 24px;
            }
            .instructions {
              display: inline-block;
              padding: 5px 10px;
              background: linear-gradient(90deg, #FAC479, #FBE6C7, #FAC479);
              border-radius: 4px;
              font-size: var(--fontSize12);
              color: #945606;
              font-weight: 500;
            }
          }
          .pricing {
            font-size: var(--fontSize12);
            color: var(--color999);
            font-weight: 500;
            span {
              text-decoration: line-through;
            }
          }
        }
      }
      .right {
        width: 120px;
        height: 40px;
        background: var(--colorfff);
        border: 1px solid var(--colored6d00);
        font-size: var(--fontSize16);
        color: var(--colored6d00);
        font-weight: bold;
        text-align: center;
        line-height: 38px;
      }
      .no_inventory {
        border: 1px solid var(--colorccc);
        color: var(--colorccc);
      }
    }
    .no_goods {
      margin: 150px auto;
      width: 306px;
      height: 196px;
      cursor: pointer;
    }
  }
</style>
