import request from 'network/request';

export function getScreeningOptions() {
  return request({
    url: '/goods/configItem/po/class_label_show',
    method: 'get'
  });
}
export function getBrandList() {
  return request({
    url: '/goods/ancillary/getBrands',
    method: 'get'
  });
}
export function getPressList() {
  return request({
    url: '/goods/ancillary/getCompanys',
    method: 'get'
  });
}
export function getAwardList() {
  return request({
    url: '/goods/ancillary/getPrizes',
    method: 'get'
  });
}
export function getGoodsList(params) {
  return request({
    url: '/goods/home/extend/postSearch/po',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function downloadBooks(params) {
  return request({
    url: '/goods/home/extend/postSearch/po/export',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    responseType: 'blob'
  });
}
