<template>
  <section class="home">
    <NavBar />
    <Header ref="Header" type="search" :hotWord="searchHotWord" @ordinarySearch="ordinarySearchHandle"
      @search="searchHandle" />
    <div class="content box pb150">
      <Screening ref="Screening" :screeningOptions="screeningOptions" @labelClick="labelClickHandle"
        @downloaddBook="downloaddBook" />
      <GoodsList ref="GoodsList" :goodsList="goodsList" :total="paging.total" :pages="paging.pages"
        :pageIndex.sync="paging.pageIndex" :pageSize.sync="paging.pageSize" @search="searchHandle"
        @clearSearchConditions="clearSearchConditions" />
      <Pagination :pageIndex.sync="paging.pageIndex" :pageSize.sync="paging.pageSize" :total="paging.total"
        @change="getGoodsList" />
      <Footer type="home" :isWhat="isWhat" />
    </div>
    <div class="footer2">
      <div class="footer_picture">
        <img class="mainpic" src="~assets/images/home-page-footer.jpg">
      </div>
      <div class="footer_text">
        <span class="textItem">地址：福建省福州市仓山区盖山镇盘屿路869号所金山工业集中区</span>
        <span class="textItem">联系电话:0591-87448350</span>
        <span class="textItem" @click="jumplink">网站备案号:闽ICP备2023010113号</span>
      </div>
    </div>
  </section>
</template>

<script>
import NavBar from 'components/NavBar/index.vue';
import Header from 'components/Header/index.vue';
import Screening from './components/Screening.vue';
import GoodsList from './components/GoodsList.vue';
import Pagination from 'components/Pagination/index.vue';
import Footer from 'components/Footer/index.vue';
import {
  getSearchHotWord
} from 'api/share.js';
import {
  getScreeningOptions,
  getBrandList,
  getPressList,
  getAwardList,
  getGoodsList,
  downloadBooks
} from 'api/home.js';

export default {
  components: {
    NavBar,
    Header,
    Screening,
    GoodsList,
    Pagination,
    Footer
  },

  created() {
    if (this.$route.query.keyword) {
      this.searchData.keyword = this.$route.query.keyword;
    }
    // this.getSearchHotWord();
    this.getScreeningOptions();
    this.$nextTick(() => {
      this.getGoodsList();
    });
  },

  mounted() {
    if (this.$route.query.keyword) {
      this.$refs.Header.keyword = this.$route.query.keyword;
    }
    let that = this;
    window.onscroll = function () {
      // 滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // 可视区高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      // 滚动条总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      // 滚动条到底
      if (!that.first && scrollTop + windowHeight == scrollHeight) {
        that.isWhat = true;
      } else {
        that.isWhat = false;
      }
      that.first = false;
    };
  },

  data() {
    return {
      searchHotWord: [],
      screeningOptions: [],
      searchData: {
        keyword: ''
      },
      paging: {
        pageIndex: 1,
        pageSize: 10,
        pages: 0,
        total: 0
      },
      goodsList: [],
      isNoData: false,
      first: true,
      isWhat: false
    };
  },

  computed: {
    token() {
      return this.$store.state.user.token;
    }
  },

  methods: {
    // 获取搜索热词
    getSearchHotWord() {
      getSearchHotWord().then(res => {
        if (res.code) {
          this.$message.error(res.message);
          return;
        }
        this.searchHotWord = res.data;
      });
    },
    // 获取筛选项
    getScreeningOptions() {
      getScreeningOptions().then(async res => {
        if (res.code) {
          this.$message.error(res.message);
          return;
        }
        this.screeningOptions = res.data;
        this.screeningOptions.forEach(item => {
          item.isMultiSelect = false;
          item.child.forEach(i => {
            this.$set(i, 'isSelected', false);
          });
        });
        await this.getBrandList();
        await this.getPressList();
        await this.getAwardList();
      });
    },
    getBrandList() {
      getBrandList().then(res => {
        if (res.code) {
          this.$message.error(res.message);
          return;
        }
        res.data.forEach(item => {
          item.label = item.brandName;
          item.value = item.brandId;
        });
        this.screeningOptions.push({
          label: '图书品牌',
          child: res.data
        });
      });
    },
    getPressList() {
      getPressList().then(res => {
        if (res.code) {
          this.$message.error(res.message);
          return;
        }
        res.data.forEach(item => {
          item.label = item.companydName;
          item.value = item.companyId;
        });
        this.screeningOptions.push({
          label: '出版社',
          child: res.data
        });
      });
    },
    getAwardList() {
      getAwardList().then(res => {
        if (res.code) {
          this.$message.error(res.message);
          return;
        }
        res.data.forEach(item => {
          item.label = item.prizeName;
          item.value = item.prizeId;
        });
        this.screeningOptions.push({
          label: '获得奖项',
          child: res.data
        });
      });
    },
    // 获取商品列表
    getGoodsList() {
      this.$refs.GoodsList.isAllSelect = false;
      let loading = this.Loading.service();
      getGoodsList({
        ...this.searchData,
        pageIndex: this.paging.pageIndex,
        pageSize: this.paging.pageSize
      }).then(res => {
        loading.close();
        if (res.code) {
          this.$message.error(res.message);
          return;
        }
        if (res.data.page.records.length === 0) {
          this.isNoData = true;
        } else {
          this.isNoData = false;
        }
        this.goodsList = res.data.page.records;
        this.goodsList.forEach(item => {
          this.$set(item, 'isSelected', false);
        });
        this.paging.total = res.data.page.total;
        this.paging.pages = res.data.page.pages;
      });
    },
    // 搜索回调
    ordinarySearchHandle(keyword) {
      this.$refs.Screening.labelList = [[], []];
      this.$refs.Screening.valueList = [{}, {}];
      this.$refs.GoodsList.hasValidNum = false;
      this.searchData = {
        keyword
      };
      this.getGoodsList();
    },
    // 高级搜索回调
    searchHandle(searchData) {
      Object.assign(this.searchData, searchData);
      this.paging.pageIndex = 1;
      this.paging.pageSize = 10;
      this.getGoodsList();
    },
    // 选择标签回调
    labelClickHandle(obj) {
      switch (obj.type) {
        case 'delete':
          obj.str === '图书品牌' ? this.searchData.brandId = [] :
            obj.str === '获得奖项' ? this.searchData.prodPrizeId = [] :
              obj.str === '出版社' ? this.searchData.pubComId = [] :
                obj.str === '年龄分类' ? this.searchData.labelIdList[0] = [] : this.searchData.labelIdList[1];
          this.getGoodsList();
          return;
        case 'brand':
          this.searchData.brandId = obj.valueList;
          this.getGoodsList();
          return;
        case 'award':
          this.searchData.prodPrizeId = obj.valueList;
          this.getGoodsList();
          return;
        case 'press':
          this.searchData.pubComId = obj.valueList;
          this.getGoodsList();
          return;
        default:
          this.searchData.labelIdList = obj.valueList;
          this.getGoodsList();
          return;
      }
    },
    clearSearchConditions() {
      this.searchData = this.$options.data().searchData;
      this.paging = this.$options.data().paging;
      this.getGoodsList();
    },
    downloaddBook() {
      downloadBooks({
        ...this.searchData,
        pageIndex: this.paging.pageIndex,
        pageSize: this.paging.pageSize
      }).then(res => {
        if (res.code) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success('操作成功！');
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([res]));
        a.setAttribute('download', '书单列表' + '.xlsx');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      });
    },
    jumplink(){
      window.location.href="https://beian.miit.gov.cn/";
    }
  },

  watch: {
    'paging.pageIndex'() {
      this.getGoodsList();
    },
    'paging.pageSize'() {
      this.getGoodsList();
    }
  }
};
</script>

<style lang="less" scoped>
.home {
  .content {}

  .footer2 {
    width: 100%;
    height: 300px;

    .footer_picture {
      width: 100%;
      display: flex;
      justify-content: center;

      .mainpic {
        width: 1380px;
        height: 158px;
      }
    }
    .footer_text
    {
      width: 100%;
      display: flex;
      justify-content: center;
      .textItem
      {
        color: rgb(124, 139, 153);
        font-size: 23px;
        margin-left: 30px;
      }
    }
  }

}
</style>
